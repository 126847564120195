
import { Vue, Options } from 'vue-class-component'
import ProductTable from '../components/ProductTable.vue'
import axios from '@/plugins/axios'
import { ElMessageBox } from 'element-plus'
import { ElNotification } from 'element-plus'

interface Result {
  total: number
  data: []
}

@Options({
  components: {
    ProductTable
  }
})
export default class ProductPage extends Vue {
  listProduct = []
  currentPage = 1
  totalRows = 0
  perPage = 20
  target = null
  filter = {}
  headers = {
    'token-user': localStorage.getItem('tokenUser'),
    account: localStorage.getItem('account')
  }
  showMassSync = false
  syncData = {
    dataDate: 'All products',
    skus: ''
  }
  disabledDate = (time: Date) => {
    return time.getTime() > Date.now()
  }
  listChooseDate = [
    'All products',
    'From yesterday',
    'From last week',
    'From last month',
    'From last year'
  ]

  async getList(page = 0) {
    await this.$store.dispatch('setLoading', true, { root: true })
    let res: Result = {
      total: 0,
      data: []
    }
    res = await axios.get('user/product', {
      headers: {
        'token-user': localStorage.getItem('tokenUser'),
        account: localStorage.getItem('account'),
        connectionTo: this.target
      },
      params: {
        condition: this.filter,
        account: localStorage.getItem('account'),
        connectionTo: this.target,
        page: page,
        limit: this.perPage
      }
    })
    if (res) {
      this.totalRows = res.total
      this.listProduct = res.data
    }
    await this.$store.dispatch('setLoading', false, { root: true })
  }
  handleCurrentChange(index = 1) {
    this.currentPage = index
    this.getList(index)
  }
  handleSizeChange(val: number) {
    this.currentPage = 1
    this.perPage = val
    this.getList(this.currentPage)
  }

  handleAfter() {
    this.getList(0)
  }
  handleSearchProduct(data: any) {
    this.target = data.target
    this.filter = data.condition
    this.getList(0)
  }

  // Original Cron function
  showMassSyncBtn() {
    this.showMassSync = true
  }
  handleSelectDate() {
    var today = new Date()
    var obj: any = {
      'From yesterday': new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 1
      ),
      'From last week': new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 7
      ),
      'From last month': new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        today.getDate()
      ),
      'From last year': new Date(
        today.getFullYear() - 1,
        today.getMonth(),
        today.getDate()
      )
    }

    this.listChooseDate.forEach((element) => {
      if (element == this.syncData.dataDate) {
        this.syncData.dataDate = obj[element]
      }
    })
    if (this.syncData.dataDate == undefined) {
      this.syncData.dataDate = 'All products'
    }
  }
  cancelMassSync() {
    this.showMassSync = false
    this.syncData.dataDate = 'All products'
  }
  async handleMassSync() {
    await this.$store.dispatch('setLoading', true, { root: true })
    let data: any = this.syncData
    if (data.dataDate === 'sku' && !data.skus) {
      await this.$store.dispatch('setLoading', false, { root: true })
      return ElNotification({
        title: 'Error',
        message: 'The skus field cannot be empty',
        type: 'error'
      })
    }
    if (['All products', 'sku'].includes(data.dataDate)) {
      data.dataDate = undefined
    } else  {
      data.skus = ""
      data.dataDate = new Date(data.dataDate).toISOString()
    }
    await axios
      .post(
        'user/connect/product-sync-all',
        {
          id: this.$store.state['targetConnection'],
          data: data
        },
        {
          headers: this.headers
        }
      )
      .then((response) => {
        if (response.status) {
          this.showMassSync = false
          this.syncData = {
            dataDate: 'All products',
            skus: ''
          }
          ElMessageBox.alert(
            'Product Mass Sync would be started in the next cron run.',
            'Success',
            {
              type: 'success'
            }
          )
        }
      })
      .catch((error) => {
        ElMessageBox.alert(error, 'Error', {
          type: 'error'
        })
        console.log(error)
      })
    await this.$store.dispatch('setLoading', false, { root: true })
  }
}
